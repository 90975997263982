<template>
  <div class="view editor">
    <toolbar :status="toolbarStatus"/>
    <div class="flow">
      <tab-menu @togglesplit="onToggleSplit" />
      <main :class="{ split }" v-if="document">
        <code-pane v-if="split" @updateDocument="onUpdate"/>
        <router-view @updateDocument="onUpdate" />
      </main>
    </div>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import TabMenu from '@/components/TabMenu.vue'
import CodePane from '@/views/editor_panes/CodePane.vue'

import isEqual from 'lodash.isequal'
import debounce from 'lodash.debounce'

export default {
  name: 'Editor',
  components: { Toolbar, TabMenu, CodePane },
  data () {
    return {
      split: false,
      toolbarStatus: '',
    }
  },
  computed: {
    document () {
      return this.$store.state.editor.document
    },
  },
  methods: {
    onToggleSplit (split) {
      this.split = split
    },
    onUpdate (updated) {
      this.toolbarStatus = 'You have unsaved changes.'
      this.debouncedUpdate(updated)
    },
    debouncedUpdate: debounce(function (updated) {
      if (isEqual(this.document, updated)) return
      this.toolbarStatus = 'Applying changes...'
      this.$store.dispatch('updateDocument', updated)
        .then(() => {
          this.toolbarStatus = 'All changes saved.'
        })
        .catch(err => {
          this.toolbarStatus = 'An error occurred saving your changes...'
          console.error(err)
        })
    }, 1000),
  },
  async mounted () {
    this.$store.commit('resetEditor')
    const docID = this.$route.params.id
    await this.$store.dispatch('bindDocument', docID)
    this.$store.commit('setDocument', this.document)
    this.split = this.$route.query.split === null
  },
  unmounted () {
    this.$store.dispatch('unbindDocument')
  },
}
</script>

<style lang="scss" scoped>
.editor {
  height: 100%;

  .flow {
    position: relative;
    height: calc(100% - #{ $toolbar-height });
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;

    @include md {
      flex-direction: row;
    }

    main {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;

      &.split {
        .editor-pane {
          width: 50%;

          &:not(.code-pane) {
            border-left: 2px solid var(--c-border);
          }
        }
      }
    }
  }
}
</style>
