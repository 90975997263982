<template>
  <menu class="tab-menu">
    <li :class="{ active: currentTab === 'details' }">
      <router-link class="btn" :to="{ path: `/edit/${id}/details`, query: { split: splitParam } }" replace>
        <font-awesome-icon icon="info-circle" />
        Details
      </router-link>
    </li>
    <li v-if="!split" :class="{ active: currentTab === 'code' }">
      <router-link class="btn" :to="{ path: `/edit/${id}/code`, query: { split: splitParam } }" replace>
        <font-awesome-icon icon="code" />
        Code
      </router-link>
    </li>
    <li :class="{ active: currentTab === 'lexicon' }">
      <router-link class="btn" :to="{ path: `/edit/${id}/lexicon`, query: { split: splitParam } }" replace>
        <font-awesome-icon icon="book" />
        Lexicon
      </router-link>
    </li>
    <li :class="{ active: currentTab === 'tree' }">
      <router-link class="btn" :to="{ path: `/edit/${id}/tree`, query: { split: splitParam } }" replace>
        <font-awesome-icon icon="sitemap" />
        Tree
      </router-link>
    </li>
    <li class="end">
      <button class="split-toggle" :class='{ split }' @click="toggleSplitView">
        <font-awesome-icon icon="columns" />
        Split View
      </button>
    </li>
  </menu>
</template>

<script>
export default {
  name: 'TabMenu',
  computed: {
    id () {
      return this.$route.params.id
    },
    currentTab () {
      return this.$route.name
    },
    split () {
      return this.$route.query.split === null
    },
    splitParam () {
      return this.split ? null : undefined
    },
  },
  methods: {
    toggleSplitView () {
      this.setSplitView(!this.split)
    },
    setSplitView (split) {
      const splitParam = split ? null : undefined
      const query = { ...this.$route.query, split: splitParam }
      const name = this.$route.name
      this.$router.replace({ name, query })
      this.$emit('togglesplit', split)
      if (split && this.currentTab === 'code') this.$router.replace({ path: `/edit/${this.id}/details`, query })
    },
    onResize () {
      // If the window becomes too small, toggle out of split view
      const mdMin = 900
      if (this.split && window.innerWidth < mdMin) this.setSplitView(false)
    },
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss" scoped>
.tab-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  border-top: 2px solid var(--c-border);
  align-items: center;

  @include md {
    flex-direction: column;
    border-top: none;
    border-right: 2px solid var(--c-border);
    width: auto;
    justify-content: start;
  }

  li {
    display: block;

    @include md {
      align-self: stretch;
    }

    a.btn, button {
      padding: 0.5rem 1rem;
      text-align: center;
      border: none;
      border-radius: 0;
      font-size: 14px;

      svg {
        margin: 0 auto;
        margin-bottom: 0.5rem;
        font-size: 2rem;
        display: block;
        color: var(--c-txt-secondary);
        transition: color 0.1s ease-in-out;
      }

      @include sm {
        font-size: 1rem;

        svg {
          font-size: 3rem;
        }
      }

      @include md {
        padding: 1rem 0.5rem;
        width: 100%;
      }

      &:hover {
        svg {
          color: var(--c-txt-alt);
        }
      }

      &:focus {
        outline: 2px solid var(--c-bg-alt);
        outline-offset: -4px;

        &::before {
          display: none;
        }

        &:hover {
          outline-color: var(--c-bg);
        }
      }
    }

    &.active {
      a.btn, button {
        background: var(--c-bg-alt);
        color: var(--c-txt-alt);

        svg {
          color: var(--c-txt-alt);
        }

        &:focus {
          outline-color: var(--c-bg);
        }
      }
    }

    &.end {
      align-self: auto;
      margin-block-start: auto;
      display: none;

      @include md {
        display: block;
      }

      button {
        font-size: 12px;

        &:hover {
          background: var(--c-bg);
          color: var(--c-txt-emphasis);

          svg {
            color: var(--c-txt-emphasis);
          }
        }

        &:focus {
          outline-color: var(--c-txt-secondary);

          &:hover {
            outline-color: var(--c-txt-emphasis);
          }
        }

        &.split-toggle {
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          margin-block-end: 1rem;
          font-size: 10px;
          line-height: 0.9;
          white-space: nowrap;

          svg {
            margin-bottom: 0;
          }

          &.split {
            background-color: var(--c-txt-primary);
            color: var(--c-bg);

            svg {
              color: var(--c-bg);
            }
          }

          &:focus {
            outline: none;
          }
        }

        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
