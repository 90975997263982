<template>
  <menu type="toolbar" class="toolbar">
    <h2 class="title">Editor</h2>
    <li>
      <button type="menu">File</button>
      <ul class="dropdown" id="file-menu">
        <!--
        <li>
          <button type="button">
            New <span class="shortcut">CTRL+N</span>
          </button>
        </li>
        <li>
          <button type="button">
            Open... <span class="shortcut">CTRL+O</span>
          </button>
        </li>
        <li>
          <button type="button">
            Save <span class="shortcut">CTRL+S</span>
          </button>
        </li>
        <li>
          <button type="button">
            Save As... <span class="shortcut">CTRL+SHIFT+S</span>
          </button>
        </li>
        -->
        <li>
          <button type="button" @click="downloadSource">
            <font-awesome-icon icon="download" />
            Download Source
          </button>
          <a style="display: none" ref="downloader"></a>
        </li>
      </ul>
    </li>
    <!--
    <li>
      <button type="menu" @focus="show = 'edit'" @blur="show = edit">Edit</button>
      <ul class="dropdown" id="edit-menu" v-if="show === 'edit'">
        <li>
          <button type="button">
            Cut <span class="shortcut">CTRL+X</span>
          </button>
        </li>
        <li>
          <button type="button">
            Copy <span class="shortcut">CTRL+C</span>
          </button>
        </li>
        <li>
          <button type="button">
            Paste <span class="shortcut">CTRL+V</span>
          </button>
        </li>
        <li>
          <button type="button">
            Find  <span class="shortcut">CTRL+F</span>
          </button>
        </li>
        <li>
          <button type="button">
            Find &amp; Replace <span class="shortcut">CTRL+H</span>
          </button>
        </li>
      </ul>
    </li>
    -->
    <li>
      <router-link class="btn" to="/docs" target="_blank">Help</router-link>
    </li>
    <li class="status">
      {{ status }}
    </li>
    <li class="right">
      <future-warning />
      <router-link to="/" class="brand">Conlang Workbench</router-link>
      <user-controls />
    </li>
  </menu>
</template>

<script>
import UserControls from '@/components/UserControls.vue'
import FutureWarning from '@/components/FutureWarning.vue'

export default {
  name: 'Toolbar',
  components: { UserControls, FutureWarning },
  props: { status: String },
  data () {
    return {
      show: null,
      shortcuts: {
        'CTRL+N': 'new',
        'CTRL+O': 'open',
        'CTRL+S': 'save',
        'CTRL+SHIFT+S': 'save-as',
        'CTRL+X': 'cut',
        'CTRL+C': 'copy',
        'CTRL+V': 'paste',
        'CTRL+F': 'find',
        'CTRL+H': 'replace',
      },
    }
  },
  methods: {
    downloadSource () {
      const link = this.$refs.downloader
      const source = this.$store.state.editor.code
      const docName = this.$store.state.editor.document.name
      const fileName = docName.replace(/[^a-z0-9]+/gi, '-').toLowerCase() + '.lang'
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(source))
      link.setAttribute('download', fileName)
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 2px solid var(--c-border);

  .title {
    font-size: 1rem;
    font-weight: 800;
    padding: 0.5em;
    display: inline-block;
    text-align: center;
    color: var(--c-txt-secondary);
    cursor: default;
  }

  .brand {
    @extend .title;

    margin-left: auto;
    margin-right: 1rem;
    cursor: pointer;
    display: none;

    @include sm {
      display: inline-block;
    }
  }

  li {
    position: relative;

    button, a {
      display: inline-block;
      border: none;
      border-radius: 0;
      padding: 0.5rem;
      transition: color 0.1s ease-in-out,
       background-color 0.1s ease-in-out;

      &:hover,
      &[type="menu"]:focus {
        background: var(--c-bg-shade);
        color: var(--c-txt-emphasis);
      }

      &:focus::before {
        display: none;
      }

      svg {
        margin-right: 0.5rem;
        position: relative;
        top: 2px;
      }
    }

    .dropdown {
      position: absolute;
      display: none;
      flex-direction: column;
      flex-wrap: wrap;
      list-style: none;
      z-index: 100;
      margin-top: 2px;
      border: 2px solid var(--c-border);
      border-top: none;
      white-space: nowrap;

      li {
        flex: 0 0 100%;
        background: var(--c-bg);

        button {
          display: flex;
          font-size: 14px;
          width: 100%;
          text-align: left;

          .shortcut {
            font-weight: 600;
            font-size: 10px;
            line-height: 2;
            text-transform: uppercase;
            margin-left: auto;
            padding-left: 1rem;
            color: var(--c-txt-secondary);
          }
        }
      }
    }

    &:focus-within {
      .dropdown {
        display: flex;
      }
    }

    &.status {
      font-size: 1rem;
      font-weight: 300;
      padding: 0.5em;
      display: inline-block;
      text-align: center;
      color: var(--c-txt-secondary);
    }

    &.right {
      margin-left: auto;
      padding-right: 1rem;
      white-space: nowrap;
      display: flex;
    }
  }
}
</style>
